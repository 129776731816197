<template>
    <div>
        <div class="modal" v-if="modelValue" :class="{active: modelValue}" @click.self="closeModal">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <h1>Buttons</h1>
                    <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
                <div class="modal_body">
                    <div class="section_wpr">
                        <h3>Button List<button type="button" class="add_btn m-0" @click="handleCreateButton"><i class="fas fa-plus"></i>Create Button</button></h3>
                    </div>
                    <div class="tempcard">
                        <div class="load_more" v-if="loader">
                            <img src="@/assets/images/load_more.gif" alt="">
                            Loading
                        </div>
                        <ul class="mt-1" v-if="!loader">
                            <li v-for="(button, b) in contentButtons.data" :key="b">
                                <div class="tempcard_details">
                                    <h5 class="m-0">{{ button.button_name }}</h5>
                                </div>
                                <span class="view px-2" @click="handleDeleteButton(button.id)"><i class="fas fa-trash-alt text-danger p-0"></i></span>
                                <span class="view m-0" @click="handleEditButton(button)"><i class="fas fa-edit"></i></span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="pagination m-0" v-show="contentButtons.total">
                    <pagination v-model="params.page" :pages="contentButtons.last_page" :range-size="0" @update:modelValue="handlePagination" />
                </div>
            </div>
        </div>
        <edit-button v-model="editButton" :button-data="buttonData" :edit-button-watcher="editButtonWatcher" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    const EditButton  = defineAsyncComponent(() => import('@/pages/admin-setting/components/content-setting/EditButton'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        name: 'Content Button',

        data() {
            return {
                params: {
                    page: 1,
                    per_page: 10
                },
                editButton: false,
                buttonData: {},
                editButtonWatcher: 0,
            }
        },

        props: {
            modelValue: Boolean
        },

        emits: ['update:modelValue', ],

        components: {
            EditButton,
        },

        watch: {
            modelValue (value) {
                if (value) {
                    const vm = this;
                    document.body.classList.add('modal-open');

                    setTimeout(function () {
                        vm.getButtonList(vm.params);
                    }, 1000);

                } else if (!value && this.editButton) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            editButton (val) {
                if (!val) {
                    document.body.classList.remove('modal-open');
                }
            }

        },

        computed: mapState({
            contentButtons: state => state.contentSettingModule.contentButtons,
            loader: state => state.contentSettingModule.contentSettingLoader,
        }),

        methods: {
            ...mapActions({
                getButtonList: 'contentSettingModule/getButtonList',
                deleteButton: 'contentSettingModule/deleteButton',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleCreateButton () {
                const vm = this;

                vm.buttonData = {};
                vm.closeModal();
                vm.editButtonWatcher++;
                vm.editButton = true;
            },

            handleEditButton (button) {
                const vm = this;

                vm.buttonData = button;
                vm.closeModal();
                vm.editButtonWatcher++;
                vm.editButton = true;
            },

            handlePagination (page) {
                const vm = this;
                vm.getButtonList(vm.params);
            },

            handleDeleteButton (id) {
                const vm = this;

                const option = Helper.swalConfirmOptions('Are you sure?', 'You will not be able to recover this  email button.', 'Yes', false);

                Swal.fire(option).then((result) => {
                    if (result.isConfirmed) {
                        vm.params.id = id;
                        vm.deleteButton(vm.params).then((result) => {
                            const params = { page:1, per_page:10 }
                            vm.getButtonList(params);
                        });
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .pagination{
        padding: 15px 40px;
        background: #fff;
        border-top: 1px solid #eee;
    }
    .tempcard > ul > li{
        margin-bottom: 15px;
    }
</style>
